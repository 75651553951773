import { Api } from 'lib/api/instance'
import { isSspFormat, isSspMediaFormat, isSspTextFormat } from 'lib/helpers/isSsp'
import parseSlug from 'lib/helpers/parseSlug'

export default {
  namespaced: true,

  state: {
    visibleCampaignsSidebar: false,
    completedCampaigns: {
      page: 1,
      total: 0,
      data: [],
    },
    counters: {
      completed: 0,
      incompleted: 0,
    },
    campaigns: null,
    campaign: null,
    campaignType: null,
    loading: {
      campaign: true,
      campaigns: {
        completed: true,
        incompleted: true,
      },
    },
    prerollCampaigns: null,
    campaignProductLink: null,
  },

  mutations: {
    setCampaigns (state, payload) {
      state.campaigns = payload
    },
    setPrerollCampaigns (state, payload) {
      state.prerollCampaigns = payload
    },
    setCompletedCampaigns (state, payload) {
      state.completedCampaigns = {
        ...state.completedCampaigns,
        ...payload,
      }
    },
    setCompletedCampaignsPage (state, payload) {
      state.completedCampaigns.page = payload
    },
    setCampaign (state, payload) {
      state.campaign = payload
    },
    toggleLoading (state, { loading, type }) {
      if (type === 'campaign') {
        state.loading.campaign = loading
      }
      else if (type === 'incompleted') {
        state.loading.campaigns.incompleted = loading
      }
      else if (type === 'completed') {
        state.loading.campaigns.completed = loading
      }
    },
    toggleCampaignsSidebar (state, payload) {
      state.visibleCampaignsSidebar = payload
    },
    setCampaignType (state, payload) {
      state.campaignType = payload
    },
    setCampaignProductLink (state, payload) {
      state.campaignProductLink = payload
    },
  },

  actions: {
    async fetchCampaigns ({ commit, rootGetters }, toggling = true) {
      const {
        platform,
        brandisExtensionActive,
        enableSspInstreamAds,
        enableChatbotTextAds,
      } = rootGetters['widgetSettings/widget']

      try {
        if (toggling) {
          commit('toggleLoading', {
            loading: true,
            type: 'incompleted',
          })
        }

        const brandAwarenessCampaigns = await Api.get('streamer/campaigns/brand_awareness')

        const campaigns = {
          active: brandAwarenessCampaigns.active,
          inactive: brandAwarenessCampaigns.inactive,
          future: brandAwarenessCampaigns.future,
        }

        if (brandAwarenessCampaigns?.unavailable?.length) {
          campaigns.inactive.concat(brandAwarenessCampaigns.unavailable)
        }

        if (platform === 'twitch') {
          const permormanceCampaigns = await Api.get('streamer/campaigns/performance')

          if (!brandisExtensionActive) {
            campaigns.inactive = campaigns.inactive.concat(
              permormanceCampaigns.active,
              permormanceCampaigns.inactive,
              permormanceCampaigns.future,
              permormanceCampaigns.unavailable,
            )
          }
          else {
            campaigns.active = [
              ...campaigns.active,
              ...permormanceCampaigns.active,
            ]
            campaigns.inactive = [
              ...campaigns.inactive,
              ...permormanceCampaigns.inactive,
              ...permormanceCampaigns.unavailable,
            ]
            campaigns.future = [
              ...campaigns.future,
              ...permormanceCampaigns.future,
            ]
          }
        }

        // if (!enableSspInstreamAds) {
        //   const sspCampaigns = campaigns.active.filter(campaign => isSspMediaFormat(campaign.format.id))

        //   campaigns.active = campaigns.active.filter(campaign => !isSspMediaFormat(campaign.format.id))
        //   campaigns.inactive = [...campaigns.inactive, ...sspCampaigns]
        // }

        // if (!enableChatbotTextAds) {
        //   const sspCampaigns = campaigns.active.filter(campaign => isSspTextFormat(campaign.format.id))

        //   campaigns.active = campaigns.active.filter(campaign => !isSspTextFormat(campaign.format.id))
        //   campaigns.inactive = [...campaigns.inactive, ...sspCampaigns]
        // }

        commit('setCampaigns', campaigns)
      }
      finally {
        commit('toggleLoading', {
          loading: false,
          type: 'incompleted',
        })
      }
    },

    async fetchPrerollCampaigns ({ commit }, toggling = true) {
      try {
        if (toggling) {
          commit('toggleLoading', {
            loading: true,
            type: 'incompleted',
          })
        }
        const res = await Api.get('streamer/campaigns/preroll')

        commit('setPrerollCampaigns', res.data)
      }
      finally {
        commit('toggleLoading', {
          loading: false,
          type: 'incompleted',
        })
      }
    },

    async fetchCompletedCampaigns ({ commit, state, rootGetters }, campaignType, toggling = true) {
      const isDemo = rootGetters.demo
      if (isDemo) {
        return
      }

      const page = state.completedCampaigns.page

      try {
        if (toggling) {
          commit('toggleLoading', {
            loading: true,
            type: 'completed',
          })
        }

        let url = 'streamer/campaigns/closed'

        if (campaignType === 'preroll') {
          url = 'streamer/campaigns/preroll/closed'
        }

        const res = await Api.get(url, { page })
        commit('setCompletedCampaigns', res)
      }
      finally {
        commit('toggleLoading', {
          loading: false,
          type: 'completed',
        })
      }
    },

    setCompletedCampaignsPage ({ commit, dispatch }, page) {
      commit('setCompletedCampaignsPage', page)
      dispatch('fetchCompletedCampaigns')
    },

    async fetchCampaign ({ commit }, { slug, toggling = true }) {
      const campaign = parseSlug(slug)
      try {
        if (toggling) {
          commit('toggleLoading', {
            loading: true,
            type: 'campaign',
          })
        }

        let res
        if (campaign.type === 'performance') {
          res = await Api.get('streamer/campaigns/performance/info', { slug })
        }
        if (campaign.type === 'preroll') {
          res = await Api.get('streamer/campaigns/preroll/info', { slug })
        }
        else {
          res = await Api.get('streamer/campaigns/info', { slug })
        }
        commit('setCampaignType', campaign.type)
        commit('setCampaign', res.data)
      }
      finally {
        commit('toggleLoading', {
          loading: false,
          type: 'campaign',
        })
      }
    },

    async changeCampaignStatus ({ dispatch }, slug) {
      const { type } = parseSlug(slug)
      if (type === 'performance') {
        await Api.post('streamer/campaigns/performance/status', { slug })
      }
      else {
        await Api.post('streamer/campaigns/status', { slug })
      }
      await dispatch('fetchCampaigns', false)
    },

    showCampaignsSidebar ({ commit, dispatch, state }, slug) {
      if (state.campaign?.slug !== slug) {
        commit('setCampaign', null)
        dispatch('fetchCampaign', { slug })
      }
      commit('toggleCampaignsSidebar', true)
    },
    hideCampaignsSidebar ({ commit }) {
      commit('toggleCampaignsSidebar', false)
      commit('setCampaignProductLink', null)
    },

    async fetchProductLink ({ commit }, slug) {
      const res = await Api.get('streamer/brand_awareness/product/link', { slug })
      if (res.data?.url) {
        commit('setCampaignProductLink', res.data?.url)
      }
    },
  },

  getters: {
    campaigns: ({ campaigns }) => campaigns,
    activeCampaigns: ({ campaigns }) => campaigns?.active || [],
    futureCampaigns: ({ campaigns }) => campaigns?.future || [],
    inactiveCampaigns: ({ campaigns }) => campaigns?.inactive || [],
    unavailableCampaigns: ({ campaigns }) => campaigns?.unavailable || [],
    prerollCampaigns: ({ prerollCampaigns }) => prerollCampaigns || [],
    completedCampaigns: ({ completedCampaigns }) => completedCampaigns,
    loading: ({ loading }) => loading,
    campaign: ({ campaign }) => campaign,
    campaignType: ({ campaignType }) => campaignType,
    campaignProductLink: ({ campaignProductLink }) => campaignProductLink,
    visibleCampaignsSidebar: ({ visibleCampaignsSidebar }) => visibleCampaignsSidebar,
  },
}
